import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { PageProps } from "../../types/page"
import {
  email,
  phoneNumber,
  tel,
  chat
} from "../../components/footer/footer-constants"
import styles from "./cta-template.module.scss"
import { Page } from "@components/page/page"
import { defaultMetadata } from "@core/constants"
import { SupportBox } from "@features/about/components/support-box/support-box"
import { Icon } from "@components/icon/icon"
import { ToHTMLFormatYoutubeEmbed } from "@components/to-html/to-html-format-youtube-embed"
import { mapMetadata } from "@core/mappers/map-metadata"
import { CtaPageQuery } from "graphql/types"

export const CtaTemplate: FunctionComponent<PageProps<CtaPageQuery>> = ({
  data,
  ...props
}) => {
  const { openGraphImage } = props.pageContext
  const details = data!.contentfulGenericCtaPage

  const metadataOverrides = {
    title: details?.metadata?.title || details?.name || defaultMetadata.title,
    description: details?.metadata?.description || defaultMetadata.description,
    openGraphImage: details?.metadata?.image?.fixed?.src || openGraphImage
  }

  const metadata = mapMetadata(details?.metadata, null, {
    title: details?.name,
    image: openGraphImage
  })

  const topSupportBox = {
    title: details?.topCta?.title || "Call to Action",
    subtitle: details?.topCta?.subtitle || "Help support Ligonier",
    image: details?.topCta?.image?.fluid?.src,
    button: {
      text: details?.topCta?.buttonText || "",
      url: details?.topCta?.buttonUrl || ""
    }
  }

  const bottomSupportBox = {
    title: details?.bottomCta?.title || "Call to Action",
    subtitle: details?.bottomCta?.subtitle || "Help support Ligonier",
    image: details?.bottomCta?.image?.fluid?.src,
    button: {
      text: details?.bottomCta?.buttonText || "",
      url: details?.bottomCta?.buttonUrl || ""
    }
  }

  return (
    <Page {...props} metadata={{ ...metadata, ...metadataOverrides }}>
      <SupportBox className={styles.topSupportBox} {...topSupportBox} />
      <section className={styles.body}>
        <div className={styles.bodyContent}>
          <div className={styles.bodyCopy}>
            <ToHTMLFormatYoutubeEmbed
              text={details?.content?.childMarkdownRemark?.html || ""}
            />
          </div>
        </div>
      </section>
      <SupportBox className={styles.bottomSupportBox} {...bottomSupportBox} />
      <section className={styles.help}>
        <Icon className={styles.icon} variant="24-chat" />
        <h3 className={styles.heading}>How may we help?</h3>
        <p className={styles.helpText}>
          Our team would be happy to answer any questions you may have about our
          outreach or the ways you can help further the mission. Please contact
          us using the <a href={chat}>chat bubble</a>, <a href={email}>email</a>
          , or calling <a href={tel}>{phoneNumber}</a>.
        </p>
      </section>
    </Page>
  )
}

export default CtaTemplate

export const query = graphql`
  query CtaPage($id: String) {
    contentfulGenericCtaPage(id: { eq: $id }) {
      id
      name
      metadata {
        ...Metadata
      }
      topCta {
        title
        subtitle
        image {
          fluid {
            src
          }
        }
        buttonText
        buttonUrl
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      bottomCta {
        title
        subtitle
        image {
          fluid {
            src
          }
        }
        buttonText
        buttonUrl
      }
    }
  }
`
